import { Link } from "react-router-dom";
import styles from "./Success.module.css";

function Sucess() {
  return (
    <div className={styles.Page}>
      <div className={styles.container}>
        <h1>Payment Successful!</h1>
        <p>
          Thank you for your purchase. <br></br> Check your email for your
          Ticket.
        </p>
        <Link to="/" className={styles.button}>
          Return to Homepage
        </Link>
        <footer>&copy; 2024 Ticket Company. All Rights Reserved.</footer>
      </div>
    </div>
  );
}

export default Sucess;
