// 1) Api for fetching the Ticket options

const API_URL = "https://disciplinary-primate-prodtech-17a64b79.koyeb.app/ticketing/api";

const API_TICKET_URL = `${API_URL}/v1/event?category=THEATER_AND_PERFORMING_ARTS`;
const API_VERIFY_TRANSACTION = `${API_URL}/v1/transaction/verify`;
const API_BUY_TICKET = `${API_URL}/v1/transaction/buy-ticket`;

export async function getEventInfo() {
    const res = await fetch(`${API_TICKET_URL}`);

    if (!res.ok) throw Error("Failed to get Tickets");
    const {data} = await res.json();
    return data;
}

export async function buyTickets(formData) {
    return (await fetch(`${API_BUY_TICKET}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
    })).json();
}

export async function verifyTransaction(reference) {
    return (await fetch(
        `${API_VERIFY_TRANSACTION}?reference=${reference}`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }
    )).json();
}

