import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import logo from './logo.svg';
import './App.css';
import StagePlay from "./StagePlay";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Sucess from "./Sucess";



const router = createBrowserRouter([
    {
        // errorElement: <Error />,
        children: [
            {
                path: "/",
                element: <StagePlay />,
            },
            {
                path: "success",
                element: <Sucess />,
            },
        ],
    },
]);

function App() {
    return <RouterProvider router={router} />;
  /*return (
    <div className="App">
      <StagePlay/>
      {/!*<header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>*!/}
    </div>
  );*/
}

export default App;
