// @ts-nocheck
import {
    CalendarIcon,
    MapPinIcon,
    PhoneIcon,
    TicketIcon,
    MinusIcon,
    PlusIcon,
    ShoppingCartIcon,
    ClockIcon,
    MessageSquareIcon,
    UserIcon,
    UserCircleIcon,
    MailIcon,
    Loader2Icon,
} from "lucide-react";
import React, {useEffect, useState} from "react";
import {getEventInfo, buyTickets, verifyTransaction} from "./services/api";
// @ts-ignore
import PaystackPop from '@paystack/inline-js';
import {useNavigate} from "react-router-dom";

const LoadingSpinner = () => <Loader2Icon className="w-5 h-5 animate-spin" />;

function StagePlay() {
    const [selectedLocation, setSelectedLocation] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const [selectedTime, setSelectedTime] = useState("");
    const [ticketsCountMap, setTicketsCountMap] = useState(new Map<string, number>());
    const [locationAddress, setLocationAddress] = useState(new Map<string, string>());
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [eventLocationMap, setEventLocationMap] = useState(new Map<string, Map<string, Map<string, any>>>());
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [eventDateMap, setEventDateMap] = useState(new Map<string, Map<string, any>>());
    const [scheduleTickets, setScheduleTickets] = useState(new Map<string, any>());
    const [isLoading, setIsLoading] = useState(false);

    // This is to navigate to another page
    const navigate = useNavigate();
    let totalAmount = 0;
    ticketsCountMap.forEach((count: number, ticketId: string) => {
        if (scheduleTickets.has(ticketId)) {
            const ticket = scheduleTickets.get(ticketId);
            const price = ticket.price ?? 0;
            totalAmount += count * price;
        }
    })
    // const totalAmount = regularTickets * REGULAR_PRICE + vipTickets * VIP_PRICE;

    const doesNotHaveKeyInMap = (map: Map<string, any>, key: string) => {
        return !map.has(key);
    };

    useEffect(() => {
        const fetchTickets = async () => {
            try {
                const event = await getEventInfo();
                const scheduleData = event.content[0].schedules;

                scheduleData.forEach((schedule: {
                    eventStartTime: string;
                    state: string;
                    location: string;
                    id: string;
                    tickets: any;
                    eventStartDay: any;
                }) => {
                    const key = schedule.state;
                    const value = schedule;

                    // console.log(key, value);

                    setLocationAddress(prevMap => {
                        const newMap = new Map(prevMap);
                        if (!newMap.has(key)) {
                            newMap.set(key, value.location);
                        }
                        return newMap
                    })

                    setEventLocationMap(prevMap => {

                        const newMap = new Map(prevMap);
                        if (!newMap.has(key)) {
                            newMap.set(key, new Map());
                        }

                        if (newMap.get(key)?.size === 0) {
                            newMap.get(key)?.set(value.eventStartDay, new Map());
                            newMap.get(key)?.get(value.eventStartDay)?.set(value.eventStartTime, value);
                        }

                        //If Day does not exist for location, add it
                        if (doesNotHaveKeyInMap(newMap.get(key) ?? new Map(), value.eventStartDay)) {
                            newMap.get(key)?.set(value.eventStartDay, new Map());
                            newMap.get(key)?.get(value.eventStartDay)?.set(value.eventStartTime, value);
                        } else {

                            //If Time does not exist for day, add it
                            if (doesNotHaveKeyInMap(newMap.get(key).get(value.eventStartDay) ?? new Map(), value.eventStartTime)) {
                                newMap.get(key)?.get(value.eventStartDay)?.set(value.eventStartTime, value);
                            }
                        }
                        return newMap;
                    });

                    // console.log(eventLocationMap);
                })
            } catch (error) {
                console.error("Error fetching tickets:", error);
            }
        };
        fetchTickets();
    }, []);


    const handleSubmit = async (event: any) => {
        event.preventDefault();
        setIsLoading(true);

        const schTickets: any[] = []
        ticketsCountMap.forEach((quantity: number, ticketId: string) => {
            if (scheduleTickets.has(ticketId)) {
                if (quantity > 0) {
                    schTickets.push({
                        ticketConfigId: ticketId,
                        ticketCount: quantity,
                    });
                }
            }
        })
        const scheduleId = eventLocationMap.get(selectedLocation)?.get(selectedDate)?.get(selectedTime).id

        const formData = {
            firstName,
            lastName,
            phone: "",
            email,
            tickets: [
                {
                    eventScheduleId: scheduleId,
                    scheduleTicket: schTickets
                }
            ],
        };

        try {
            // console.log(formData)

            const data = await buyTickets(formData);

            // PAYSTACK API's
            const popup = new PaystackPop();
            popup.resumeTransaction(data.data.accessCode, {
                onSuccess(transaction: any) {
                    console.log(
                        "=================\nTHE CALL WAS SUCCESSFUL!!!!\n=================\n" +
                        transaction
                    );
                    const verificationData = verifyTransaction(transaction.reference);
                    verificationData.then((data) => {
                        if (data.data.paymentStatus === "SUCCESSFUL") {
                            navigate("/success");
                        }
                    })

                    setIsLoading(false);
                },
                onError: (message: any) => {
                    console.log("THE CALL FAILED " + message);
                    setIsLoading(false);
                },
            });

        } catch (error) {
            setIsLoading(false);
            console.error("Error submitting form:", error);
        }
    };

    const handleQuantityChange = (
        ticketId: string,
        action: "increase" | "decrease",
    ) => {
        setTicketsCountMap(map => {
            const prev = map.get(ticketId) ?? 0;
            return new Map(map.set(ticketId, action === "increase" ? prev + 1 : Math.max(0, prev - 1)))
        });
    };

    const isValidEmail = (email: string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const isFormValid = firstName && lastName && isValidEmail(email);

    return (
        <main className="w-full max-w-7xl mx-auto px-4 py-8">
            <div className="relative w-full aspect-video mb-12">
                <img
                    // src="https://images.unsplash.com/photo-1604929838957-99e61ea3fc64"
                    src="/images/flier-1.png"
                    alt="Hands in handcuffs"
                    className="w-full h-full object-cover rounded-lg"
                />
                <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-6">
                    <h1 className="text-4xl md:text-6xl font-bold text-white">
                        Once Upon Four Robbers
                    </h1>
                    <p className="text-xl text-white">A stage play</p>
                </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-md mb-12">
                <h2 className="text-2xl font-bold mb-4">Play Synopsis</h2>
                <p className="text-gray-700 mb-6">
                    "Once Upon Four Robbers" is a gripping exploration of the lives of four robbers caught in a web of
                    societal corruption and economic hardship. As they navigate their precarious existence, aided by a
                    magical charm that makes them invincible, the play challenges audiences to confront the complex
                    moral questions surrounding crime and justice in the society.
                </p>
                <div className="flex flex-wrap gap-6">
                    <div className="flex items-center gap-2">
                        <ClockIcon className="w-5 h-5 text-gray-600"/>
                        <span className="text-gray-700">Duration: 2 hours 30 minutes</span>
                    </div>
                    <div className="flex items-center gap-2">
                        <MessageSquareIcon className="w-5 h-5 text-gray-600"/>
                        <span className="text-gray-700">Language: English</span>
                    </div>
                    <div className="flex items-center gap-2">
                        <UserIcon className="w-5 h-5 text-gray-600"/>
                        <span className="text-gray-700">Age Rating: 13+</span>
                    </div>
                </div>
            </div>

            <div className="grid md:grid-cols-2 gap-8">
                <div className="space-y-6">
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h2 className="text-2xl font-bold mb-4">
                            Select Location, Date & Time
                        </h2>
                        <select
                            className="w-full p-2 mb-4 border rounded"
                            value={selectedLocation}
                            onChange={(e) => {
                                scheduleTickets.clear()
                                setSelectedLocation(e.target.value)
                            }}
                        >
                            <option value="">Select Venue</option>
                            {Array.from(eventLocationMap.keys()).map((venue) => (
                                <option key={venue} value={venue}>{venue}</option>
                            ))}
                        </select>

                        <select
                            className="w-full p-2 mb-4 border rounded"
                            value={selectedDate}
                            onChange={(e) => {
                                scheduleTickets.clear()
                                setSelectedTime("")
                                setSelectedDate(e.target.value)
                            }}
                            disabled={!selectedLocation}
                        >
                            <option value="">Select Date</option>
                            {Array.from(eventLocationMap.get(selectedLocation)?.keys() ?? []).map((day) => (
                                <option key={day} value={day}>{day}</option>
                            ))}
                        </select>

                        <select
                            className="w-full p-2 border rounded"
                            value={selectedTime}
                            onChange={(e) => {
                                setScheduleTickets(map => {
                                    map.clear()
                                    eventLocationMap.get(selectedLocation)?.get(selectedDate)?.get(e.target.value)
                                        .tickets.forEach((t: { id: string; name: string; price: number; }) => {
                                        map.set(t.id, t)
                                    })
                                    return map;
                                })
                                setSelectedTime(e.target.value)
                            }}
                            disabled={!selectedDate}
                        >
                            <option value="">Select Time</option>
                            {Array.from(eventLocationMap.get(selectedLocation)?.get(selectedDate)?.keys() ?? []).map((time) => (
                                <option key={time} value={time}>{time}</option>
                            ))}
                        </select>
                    </div>

                    <div className="bg-white p-6 rounded-lg shadow-md">
                        <h2 className="text-2xl font-bold mb-4">Select Tickets</h2>

                        <div className="space-y-4">
                            {
                                Array.from(scheduleTickets.values()).map(t => {
                                        const ticket = t as { id: string; name: string; price: number; }
                                        return <div key={ticket.id + "jbtw"} className="flex items-center justify-between">
                                            <div>
                                                <h3 key={ticket.id + "name"} className="font-semibold">{ticket.name}</h3>
                                                <p key={ticket.id + "price"} className="text-gray-600">₦{ticket.price}</p>
                                            </div>
                                            <div key={ticket.id + "gp3"} className="flex items-center gap-3">
                                                <button key={ticket.id + "decrease"}
                                                        onClick={() => handleQuantityChange(ticket.id, "decrease")}
                                                        className="p-1 rounded-full border"
                                                >
                                                    <MinusIcon className="w-4 h-4"/>
                                                </button>
                                                <span key={ticket.id}
                                                      className="w-8 text-center">{ticketsCountMap.get(ticket.id) ?? 0}</span>
                                                <button key={ticket.id + "increase"}
                                                        onClick={() => handleQuantityChange(ticket.id, "increase")}
                                                        className="p-1 rounded-full border"
                                                >
                                                    <PlusIcon className="w-4 h-4"/>
                                                </button>
                                            </div>
                                        </div>
                                    }
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className="bg-white p-6 rounded-lg shadow-md h-fit">
                    <h2 className="text-2xl font-bold mb-4">Order Summary</h2>

                    <div className="space-y-4 mb-6">
                        {
                            Array.from(scheduleTickets.values()).map(t => {
                                    const ticket = t as { id: string; name: string; price: number; }
                                    const ticketId = ticket.id

                                    return <div key={ticket.id} className="flex justify-between">
                                        <span>{ticket.name} ({ticketsCountMap.get(ticketId) ?? 0})</span>
                                        <span>₦{(ticketsCountMap.get(ticketId) ?? 0) * (ticket.price ?? 0)}</span>
                                    </div>
                                }
                            )
                        }

                        <div className="border-t pt-4">
                            <div className="flex justify-between font-bold">
                                <span>Total Amount</span>
                                <span>₦{totalAmount}</span>
                            </div>
                        </div>
                    </div>

                    <div className="space-y-4 mb-6">
                        <div className="relative">
                            <UserCircleIcon
                                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5"/>
                            <input
                                type="text"
                                placeholder="First Name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                className="w-full pl-10 p-2 border rounded"
                                required
                            />
                        </div>
                        <div className="relative">
                            <UserCircleIcon
                                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5"/>
                            <input
                                type="text"
                                placeholder="Last Name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                className="w-full pl-10 p-2 border rounded"
                                required
                            />
                        </div>
                        <div className="relative">
                            <MailIcon
                                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 w-5 h-5"/>
                            <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full pl-10 p-2 border rounded"
                                required
                            />
                        </div>
                    </div>

                    <button
                        className="w-full bg-red-600 text-white px-6 py-3 rounded-lg text-lg font-semibold hover:bg-red-700 transition-colors flex items-center justify-center gap-2"
                        disabled={!isFormValid || totalAmount === 0 || isLoading}
                        /*className={`w-full py-2 rounded text-white ${
                            isFormValid && totalAmount > 0
                                ? "bg-red-600"
                                : "bg-gray-300 cursor-not-allowed"
                        }`}*/
                        onClick={handleSubmit}
                    >
                        {isLoading ? (
                            <>
                                <LoadingSpinner />
                                Processing...
                            </>
                        ) : (
                            <>
                                <ShoppingCartIcon className="w-5 h-5" />
                                Complete Purchase
                            </>
                        )}
                        {/*<ShoppingCartIcon className="w-5 h-5"/>
                        Complete Purchase*/}
                    </button>

                    <div className="mt-6 space-y-3 text-sm text-gray-600">
                        <div className="flex items-center gap-2">
                            <MapPinIcon className="w-4 h-4"/>
                            <p>{locationAddress.get(selectedLocation) || "Select a venue"}</p>
                        </div>
                        <div className="flex items-center gap-2">
                            <CalendarIcon className="w-4 h-4"/>
                            <p>
                                {selectedDate
                                    ? new Date(selectedDate).toLocaleDateString()
                                    : "Select a date"}
                            </p>
                        </div>
                        <div className="flex items-center gap-2">
                            <TicketIcon className="w-4 h-4"/>
                            <p>{selectedTime ? selectedTime : "Select a time"}</p>
                        </div>
                    </div>

                </div>
            </div>
            <div className="mt-8 text-center bg-gray-100 p-6 rounded-lg">
                <p className="text-lg mb-4">Contact Information</p>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="flex items-center justify-center gap-2">
                        <PhoneIcon className="w-5 h-5"/>
                        <a
                            href="tel:08023130241"
                            className="text-xl font-bold hover:text-gray-600 transition-colors"
                        >
                            08023130241,
                        </a>
                        <a
                            href="tel:08083029204"
                            className="text-xl font-bold hover:text-gray-600 transition-colors"
                        >
                            08083029204
                        </a>
                    </div>
                    <div className="flex items-center justify-center gap-2">
                        <MailIcon className="w-5 h-5"/>
                        <a
                            href="mailto:info@blank.com.ng"
                            className="text-xl font-bold hover:text-gray-600 transition-colors"
                        >
                            info@blank.com.ng,
                        </a>
                        <a
                            href="mailto:info.miscellaneousent@gmail.com"
                            className="text-xl font-bold hover:text-gray-600 transition-colors"
                        >
                            info.miscellaneousent@gmail.com
                        </a>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default StagePlay;